import { useState } from "react"
import { Dialog } from "@headlessui/react"
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline"
import { NavLink } from "@remix-run/react"
import { Logo } from "~/components/logo"

export let HeaderNavMenu = () => {
  const menuItems = [
    { name: "Did You Know?", href: "/#did-you-know" },
    { name: "Speed Test", href: "/#speed-test" },
    { name: "Pricing", href: "/#pricing" },
    { name: "Contact Us", href: "/#contact-us" },
    { name: "About", href: "/about" },
    { name: "Blog", href: "/blog" },
  ]

  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="sticky inset-x-0 top-0 z-50 border-b-2 border-gray-200 bg-white drop-shadow-sm">
      <nav
        className="mx-auto flex h-16 max-w-7xl items-center justify-between px-5 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1">
          <a href="/">
            <Logo />
          </a>
        </div>
        <div className="flex lg:hidden">
          <button
            className="inline-flex items-center justify-center gap-x-2 rounded-md border-orange-600 p-2 text-orange-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
            <span className="text-base">Menu</span>
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {menuItems.map((item) => (
            <NavLink
              key={item.name}
              to={item.href}
              className="text-base font-medium text-gray-900 hover:text-orange-600"
              caseSensitive
              end
            >
              {item.name}
            </NavLink>
          ))}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={() => setMobileMenuOpen(false)}
      >
        <div className="fixed inset-0 z-50" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-50 w-full overflow-y-auto bg-white sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex h-16 items-center justify-between px-5 lg:px-8">
            <a href="/">
              <Logo />
            </a>
            <button
              type="button"
              className="inline-flex items-center justify-center gap-x-2 rounded-md border-orange-600 p-2  text-orange-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
              <span className="text-base">Menu</span>
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-2">
                {menuItems.map((item) => (
                  <NavLink
                    key={item.name}
                    to={item.href}
                    className="block rounded-lg px-5 py-2 text-base leading-7 text-gray-900 hover:bg-gray-50 hover:text-orange-600"
                    onClick={() => setMobileMenuOpen(false)}
                  >
                    {item.name}
                  </NavLink>
                ))}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}

export let FooterNavMenu = () => {
  const menuItems = {
    legal: [
      { name: "Privacy Policy", href: "/privacy-policy" },
      { name: "Terms of Service", href: "/terms-of-service" },
    ],
  }

  return (
    <div className="mx-auto max-w-7xl pl-5 pr-6 lg:px-8">
      <footer
        aria-labelledby="footer-heading"
        className="relative flex items-start justify-between border-t border-gray-900/10 py-6 sm:items-center"
      >
        <a href="/">
          <Logo />
        </a>
        <div>
          <ul className="flex flex-col gap-y-2 sm:flex-row sm:items-center sm:gap-x-10">
            {menuItems.legal.map((item) => (
              <li key={item.name}>
                <NavLink
                  to={item.href}
                  className="text-base font-medium leading-6 text-gray-900 hover:text-gray-900"
                >
                  {item.name}
                </NavLink>
              </li>
            ))}
          </ul>
        </div>
      </footer>
    </div>
  )
}
